import {Model} from '@/types/model';
import {setDoc, deleteDoc, generateId, getCollectionRef, getDocRef, getDoc, getCollection} from '../libs/firestore';


function getModels(projectId: string | string[] | undefined) {
  return getCollectionRef(`projects/${projectId}/models`);
}

function getModelsOnce(projectId: string | string[] | undefined) {
  return getCollection(`projects/${projectId}/models`);
}

function getModelSubscription(projectId: string, modelId: string) {
  return getDocRef(`projects/${projectId}/models/${modelId}`);
}

function getModelOnce(projectId: string, modelId: string) {
  return getDoc(`projects/${projectId}/models/${modelId}`);
}

function createModel(projectId: string, model: Partial<Model>) {
  const id = generateId();
  return setDoc(`projects/${projectId}/models/${id}`, model).then(() => {
    return id;
  });
}

function updateModel(projectId: string, id: string, model: Partial<Model>) {
  return setDoc(`projects/${projectId}/models/${id}`, model, true);
}

function deleteModel(projectId: string, id: string) {
  return deleteDoc(`projects/${projectId}/models/${id}`);
}

export {createModel, getModelsOnce, getModelSubscription, getModelOnce, updateModel, deleteModel, getModels };
