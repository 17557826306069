import {createContext, useContext, useEffect, useState} from "react";
import {useAuth} from "@/libs/auth";
import {useRouter} from "next/router";

const permsContext = createContext({});

export function PermsProvider({children}: any): any {
  const perms = GetPermsInfo();
  return <permsContext.Provider value={perms}>{children} </permsContext.Provider>;
}

export const usePerms = (): any => {
  return useContext(permsContext);
};

function GetPermsInfo() {
  const [level, setLevel] = useState(-5);

  // const router = useRouter();
  // const {projectId, modelId} = router.query;

  // this can be expanded in the future to handle permissions controls.
  // for now, this just handles view only collaborators

  const {user} = useAuth();
  useEffect(() => {
    if (!user) {
      setLevel(-5);
    } else {
      setLevel(5)
    }
  }, [user])


  return {
    level
  };
}
