import Head from "next/head";
import {NextRouter} from "next/router";
import {encodeGetParams} from "@/utils/general";
import {getModelOnce} from "@/services/models-store";
import {OpengraphData} from "@/types/opengraph";

export default function Opengraph({ data }: { data: OpengraphData }) {
    const { title, description, image, url } = data;
    return (
        <>
            <meta property="og:title" content={title}/>
            <meta property="og:url" content={url}/>
            <meta property="og:image" content={image}/>
            <meta property="og:description" content={description}/>
            <meta property="og:type" content="website"/>

            <meta name="twitter:site:id" content="1443659239847866369"/>
            <meta name="twitter:creator:id" content="1443659239847866369"/>
            <meta name="twitter:card" content="summary_large_image" />
        </>
    )
}