import {Team, TeamInvite, TeamUser} from '@/types/team'
import {
  addDoc,
  setDoc,
  deleteDoc,
  generateId,
  getCollectionRef,
  getDocRef,
  getDoc,
  getCollection
} from '../libs/firestore';
import {getUserOnce, updateUser} from "@/services/user-store";


function getTeams() {
  return getCollectionRef(`teams`);
}

function getUserTeams(uid: string) {
  return getCollectionRef(`users/${uid}/teams`)
}

function getTeamSubscription(teamId: string) {
  return getDocRef(`teams/${teamId}`);
}

function getTeamOnce(teamId: string) {
  return getDoc(`teams/${teamId}`);
}

function getTeamUsers(teamId: string) {
  return getCollectionRef(`teams/${teamId}/members`)
}

function getTeamInvites(teamId: string) {
  return getCollectionRef(`teams/${teamId}/invites`)
}

function getTeamMember(teamId: string, uid: string) {
  return getDoc(`teams/${teamId}/members/${uid}`);
}

function createTeam(team: Team) {
  return addDoc(`teams`, team);
}

function createTeamInvite(teamId: string, teamInvite: TeamInvite) {
  return addDoc(`teams/${teamId}/invites`, teamInvite)
}

function createTeamMember(teamId: string, uid: string, user: TeamUser) {
  setDoc(`users/${uid}/teams/${teamId}`, {
    id: teamId,
    level: user.level,
  })
  return setDoc(`teams/${teamId}/members/${uid}`, user)
}

function updateTeam(teamId: string, team: Team) {
  return setDoc(`teams/${teamId}`, team, true);
}

function deleteTeam(tid: string, uid: string) {
  deleteDoc(`users/${uid}/teams/${tid}`);
  return deleteDoc(`teams/${tid}`);
}


export {
  getTeams,
  getTeamSubscription,
  getTeamOnce,
  getUserTeams,
  getTeamUsers,
  getTeamInvites,
  getTeamMember,
  createTeam,
  createTeamInvite,
  createTeamMember,
  updateTeam,
  deleteTeam,
}
