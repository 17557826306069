import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import "firebase/analytics";
import "firebase/storage";

const FORCE_PROD = true;

// @ts-ignore
const firebaseConfig = FORCE_PROD || process.env.isProd ? {
  apiKey: "AIzaSyDIGVJmkDdkHER_ShveqRMdMDz9OfKG0Ss",
  authDomain: "blankly-6ada5.firebaseapp.com",
  databaseURL: "https://blankly-6ada5.firebaseio.com",
  projectId: "blankly-6ada5",
  storageBucket: "blankly-6ada5.appspot.com",
  messagingSenderId: "77963558433",
  appId: "1:77963558433:web:0f14077d154b7a8a032d60",
  measurementId: "G-8XYVLL854V"
} : {
  apiKey: "AIzaSyCJl_zizlLSttEORpJU2rODqa7-ibFDv7Q",
  authDomain: "blankly-dev.firebaseapp.com",
  databaseURL: "https://blankly-dev-default-rtdb.firebaseio.com",
  projectId: "blankly-dev",
  storageBucket: "blankly-dev.appspot.com",
  messagingSenderId: "644821235573",
  appId: "1:644821235573:web:9a5b733553158f710f853c",
  measurementId: "G-Z22FP66CX6"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const analytics = firebase.analytics;
const db = firebase.firestore();
const realtimedb = firebase.database();
const storage = firebase.storage();
const Timestamp = firebase.firestore.Timestamp;
const increment = firebase.firestore.FieldValue.increment;
const FieldValueTimestamp = firebase.firestore.FieldValue.serverTimestamp()

class EmptyDocumentSnapshot {
  public id: any;

  constructor() {
  }

  data() {
  }
}

// @ts-ignore
export {db, realtimedb, EmptyDocumentSnapshot, firebase, analytics, increment, storage, Timestamp, FieldValueTimestamp};
