import { createContext, useContext, useEffect } from "react";
import { useRouter } from "next/router";
import mixpanel from 'mixpanel-browser';

const mixPanelContext = createContext({});

export function MixpanelProvider({ children }: any): any {
    const mix = useMixpanelSetup();
    return <mixPanelContext.Provider value={mix}>{children}</mixPanelContext.Provider>;
}

export const useMixpanel = (): any => {
    return useContext(mixPanelContext);
};

const initMixpanel = (): any => {
    let debug = true;
    if (process.env.NODE_ENV === 'production') {
        debug = false
    } else {
        debug = true
    }
    mixpanel.init('0b2135a9ea74656218db6e1018e00674', { debug });
}

function useMixpanelSetup() {
    const router = useRouter();
    initMixpanel();
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            router.events.on('routeChangeComplete', (url) => {
                mixpanel.track("Page Change", {url})
            })
            mixpanel.track("Page Change", { url: window.location.pathname })
            return () => {
                router.events.off('routeChangeComplete', (url) => mixpanel.track("Page Change", {url}));
            };
        }
    }, [router.events])
    return {
        mixpanel
    };
}
