import LogRocket from "logrocket";
import mixpanel from "mixpanel-browser";
import { analytics } from "./firebase"
import * as Sentry from "@sentry/nextjs";

const log = (name: string, data: any = {}) => {
    analytics().logEvent(name, data);
    mixpanel.track(name, data);
    LogRocket.log(name, data);
}

const identify = (id: string) => {
    mixpanel.identify(id);
    mixpanel.alias(id);
    LogRocket.identify(id);
    Sentry.setUser({ id: id });
    analytics().setUserId(id);
}

const setAnalyticsUserData = (data: any) => {
    analytics().setUserProperties(data);

    mixpanel.people.set({$name: `${data.firstName} ${data.lastName}`, $email: data.email, ...data});
}

export { log, identify, setAnalyticsUserData }