import {NextRouter} from "next/router";
import {encodeGetParams} from "@/utils/general";
import {OpengraphData} from "@/types/opengraph";

const API_URL = `https://us-central1-${process.env.NEXT_PUBLIC_PROJECT_ID}.cloudfunctions.net/getOpengraphData?`

export default async function fetchOpenGraphData(router: NextRouter): Promise<OpengraphData> {
    const { projectId, modelId, backtestId } = router.query;

    const res = await fetch(API_URL + encodeGetParams({
        projectId: projectId,
        modelId: modelId,
        backtestId: backtestId,
    }))

    if (200 <= res.status && res.status < 300) {
        // request success
    } else {
        // if the opengraph api gave us an error message, print that out
        const err = await res.json()
            .then((json) => json.error || 'Error')
            .catch((_) => `${res.status} ${res.statusText}`)
        throw Error(`Bad response from opengraph service: ${err}`);
    }

    const opengraphData = await res.json();
    opengraphData.url = 'https://app.blankly.finance' + router.basePath + router.asPath;

    return opengraphData;
}