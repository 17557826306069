import { log, setAnalyticsUserData } from '@/libs/analytics-logging';
import { User } from '@/types/user';
import {setDoc, getDocRef, getDoc, getCollectionRef, getCollection} from '../libs/firestore';

function getUserSubscription(uid: string) {
  return getDocRef(`users/${uid}`);
}

function getUserTeamsOnce(uid: string) {
  return getCollection(`users/${uid}/teams`)
}

function getUserOnce(uid: string) {
  return getDoc(`users/${uid}`);
}

function updateUser(uid: string, user: Partial<User>) {
  log('User Update', { data: user })
  setAnalyticsUserData(user)
  return setDoc(`users/${uid}`, user, true);
}

export {getUserSubscription, getUserTeamsOnce, getUserOnce, updateUser};
